import styled from "styled-components";

export const WrapperHeader = styled.div`
  position: sticky;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding: 1rem;
  border-radius: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const ButtonFilter = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.063rem solid #d9d9d9;
  font-family: Roboto;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.375rem;
  width: 5.875rem;
  height: 2rem;
  border-radius: 0.125rem;
  padding: 0.313rem 1rem 0.313rem 1rem;
  border: 0.063rem solid #32a6d5;
  color: #32a6d5;
  background: white;
`;
export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;
