import React, { useEffect, useState } from "react";
import { ButtonFilter, ButtonGroup, WrapperHeader } from "./styles";
import Search from "antd/lib/input/Search";
import { SearchContainer } from "../styles";
import { useSearchParams } from "react-router-dom";
interface IProps {
  clearAll: () => void;
}

const TableHeader: React.FC<IProps> = ({ clearAll }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setSearchValue(searchParams.get("search") || "");
  }, [searchParams]);

  return (
    <WrapperHeader>
      <SearchContainer>
        <Search
          value={searchValue}
          placeholder="Search id, handle, first name, last name, email"
          onChange={(e) => setSearchValue(e.target.value)}
          onSearch={(value) => {
            setSearchParams({
              ...Object.fromEntries(searchParams),
              search: value,
              page: "1",
            });
          }}
        />
      </SearchContainer>
      <ButtonGroup>
        <ButtonFilter
          onClick={() => {
            setSearchValue("");
            clearAll();
          }}
        >
          Reset
        </ButtonFilter>
      </ButtonGroup>
    </WrapperHeader>
  );
};

export default TableHeader;
